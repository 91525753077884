<template>
  <div class="container has-background-white-ter">
    <section class="section is-primary pb-1">
      <div class="container">
        <div
          class="box"
          style="
            align-content: flex-start;
            flex-direction: row;
            justify-content: space-between;
            display: flex;
          "
        >
          <h4 class="title2">My Forms</h4>

          <div class="field has-addons is-pulled-right"></div>
        </div>
      </div>
    </section>
    <section v-if="loaded" class="section">
      <div class="container">
        <pre>{{ forms }}</pre>
      </div>
    </section>
    <button @click="create()">Create</button>
  </div>
</template>

<script>
//import firebase from 'firebase/compat/app';
//import 'firebase/compat/auth';
//import { Icon } from "@iconify/vue2";
//import lunch from "helpers/lunch";
//import { format, sub as datesub } from 'date-fns' ;
//import { sum } from 'pdf-lib';
//import { toast } from 'bulma-toast'

//import LunchOption from "./LunchOption.vue";

export default {
  name: "home",

  components: {
    // Icon,
  },

  data: function () {
    return {
      forms: null,
      loaded: false,
    };
  },
  methods: {
    async create() {
      this.$api
        .post("v2/staff/workflow/create/", true, true, { name: "wibble" })
        .then((response) => {
          this.forms = response;
          console.log(response);
        })
        .finally(() => {
          this.loaded = true;
        });
    },
  },

  async mounted() {
    this.loaded = false;
    this.$api
      .get("v2/staff/workflow/", true, true)
      .then((response) => {
        this.forms = response;
      })
      .finally(() => {
        this.loaded = true;
      });

    //this.loadRegister();
  },
};
</script>
